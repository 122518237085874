
import { getPresignedGetUrl } from "@/api/FileUploadService";
import { Application } from "@/models/Application";
import { defineComponent, PropType } from "vue";

export default defineComponent({
    props: {
        application: {
            type: Object as PropType<Application>,
            required: true,
        },
    },
    data() {
        return {
            hex: ["#0b78cf", "#4646FF", "#a933ff", "#8227FF", "#00808f"],
            imageUrl: undefined as string | undefined,
        };
    },
    async mounted() {
        await this.loadImage();
    },
    methods: {
        async loadImage() {
            if (!this.application.application_version_files?.hero_image) {
                this.imageUrl = undefined;

                return;
            }

            this.imageUrl = await getPresignedGetUrl(this.application.application_version_files?.hero_image);
        },
    },
});
