
import { defineComponent } from "vue";
import { Dictionary } from "vue-router/types/router";
import { getApplication } from "@/api/ApplicationService";
import { Application } from "@/models/Application";
import InstitutionAvailability from "@/components/publisher/deployment-requirements/InstitutionAvailability.vue";
import SystemRequirements from "@/components/publisher/deployment-requirements/SystemRequirements.vue";
import InputTypes from "@/components/publisher/deployment-requirements/InputTypes.vue";
import OutputTypes from "@/components/publisher/deployment-requirements/OutputTypes.vue";
import RequiredFieldsMessage from "@/components/publisher/RequiredFieldsMessage.vue";

export default defineComponent({
    metaInfo: {
        title: "Application Requirements",
    },
    components: {
        InstitutionAvailability,
        SystemRequirements,
        InputTypes,
        OutputTypes,
        RequiredFieldsMessage,
    },
    data() {
        return {
            application: {} as Application,
            requiredFieldsFilled: false,
            loading: false,
        };
    },
    created() {
        this.$store.commit("setCurrentApplicationRequiredFieldsFilled", false);
    },
    async mounted() {
        const { application_id, version_id, version_details_id } = this.$route.query as Dictionary<string>;

        this.loading = true;
        const application = await getApplication(application_id, version_id, version_details_id);
        this.application = application;
        this.loading = false;

        this.$store.commit("setApplication", application);
    },
    watch: {
        application() {
            this.$store.commit("setApplication", this.application);
        },
        requiredFieldsFilled() {
            this.$store.commit("setCurrentApplicationRequiredFieldsFilled", this.requiredFieldsFilled);
        },
    },
});
