
import Vue from "vue";
import Component from "vue-class-component";
import { InputValidationRules } from "vuetify";
import { Prop, Watch } from "vue-property-decorator";
import { ContainerCommand } from "@/models/Container";
import NamedSection from "@/components/publisher/NamedSection.vue";
import { generateCommands } from "@/api/ApplicationContainersService";
import ConfirmationModal from "@/components/shared/ConfirmationModal.vue";
import { Application, ContainerImage } from "@/models/Application";
import { Dictionary } from "cypress/types/lodash";

@Component({
    components: {
        NamedSection,
        ConfirmationModal,
    },
})
export default class ApplicationExecutables extends Vue {
    validationRules: InputValidationRules = [
        (value) => !!value || "Required",
        (value) => /^([a-z]+[-?_?/.]?[a-z0-9]+)*$/.test(value) || "Invalid container name",
    ];

    versionId = "";
    imageNames: string[] = [];
    imageNameToRemove = "";
    imageNameIndexToRemove?: number;
    removeConfirmDialog = false;
    loading = false;
    valid = false;
    containerNamesUnchanged = true;
    containerImages: ContainerImage[] = [];

    @Prop()
    application!: Application;

    @Watch("application", { deep: true, immediate: true })
    containerImagesChanged() {
        this.imageNames = [];

        if (this.application.container_repositories) {
            this.containerImages = this.application.container_repositories;
        }

        if (this.containerImages.length) {
            this.containerImages.map((image: ContainerImage) => {
                this.imageNames.push(image.name);
            });
        } else {
            this.imageNames.push("");
        }
    }

    @Watch("imageNames", { deep: true })
    containerNamesChange() {
        if (this.application.container_repositories?.length && this.application.container_repositories?.length >= 1) {
            this.containerNamesUnchanged =
                this.imageNames.length === this.application.container_repositories?.length &&
                this.imageNames.every((element: string, index: number) => {
                    if (this.application.container_repositories) {
                        return element === this.application.container_repositories[index].name;
                    }
                    return;
                });
        }
        if (this.application.container_repositories?.length) {
            if (!this.containerNamesUnchanged) {
                this.$emit("update:showCommands", false);
                this.$emit("update:containerNamesChanged", true);
            } else {
                this.$emit("update:showCommands", true);
                this.$emit("update:containerNamesChanged", false);
            }
        }
    }

    addContainerImage() {
        this.imageNames.push("");
    }

    confirmRemoveContainerImage(index: number) {
        this.imageNameIndexToRemove = index;
        this.imageNameToRemove = this.imageNames[index];

        if (!this.imageNameToRemove) {
            this.removeContainerImage();

            return;
        }

        this.removeConfirmDialog = true;
    }

    removeContainerImage() {
        if (this.imageNameIndexToRemove == undefined || this.imageNameIndexToRemove === null) {
            return;
        }

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.imageNames.splice(this.imageNameIndexToRemove!, 1);
        this.removeConfirmDialog = false;
        this.imageNameToRemove = "";
        this.imageNameIndexToRemove = undefined;
    }

    async generateCommandsForImages() {
        this.$emit("update:containersUploaded", false);
        this.$emit("update:showCommands", true);
        this.$emit("update:containerNamesChanged", false);
        this.application.container_repositories = [];

        this.imageNames.forEach((imageName: string) => {
            if (this.application.container_repositories) {
                return this.application.container_repositories?.push({ name: imageName, tagName: "" });
            }
        });
        this.$store.commit("setApplication", this.application);

        this.loading = true;

        try {
            const { version_id } = this.$route.query as Dictionary<string>;

            this.versionId = version_id;

            const commands: ContainerCommand = await generateCommands(this.versionId, this.imageNames);

            this.$emit("generated:commands", commands);
        } finally {
            this.loading = false;
        }
    }

    triggerGenerateCommandsForImages() {
        if (
            !(
                (this.application.container_repositories &&
                    this.application.container_repositories?.length > 0 &&
                    this.containerNamesUnchanged) ||
                this.imageNames[0].length === 0 ||
                !this.valid
            )
        )
            this.generateCommandsForImages();
    }

    private duplicateValidator(name: string) {
        if (this.$data.imageNames.length === 1) {
            return true;
        }

        if (this.$data.imageNames.filter((n: string) => n === name).length >= 2) {
            return "You have already added this container image name";
        }

        return true;
    }
}
