import { Developer, DeveloperProfileStatus } from "@/models/Developer";
import { HttpStatusCode } from "axios";
import Vue from "vue";
import { createAxiosInstance, ErrorMessageMap, isResultOk } from "@/utilities/axios-helpers";
import { AxiosResponse } from "axios";
import { decode } from "html-entities";

const errorMessages: ErrorMessageMap = {
    get: "Something unexpected went wrong retrieving your developer profile",
    post: "Something unexpected went wrong saving your developer profile",
    put: "Something unexpected went wrong saving your developer profile",
};

const http = createAxiosInstance(errorMessages);
const httpDevProfile = createAxiosInstance(errorMessages, undefined, true);

export async function getDeveloper(developer_id: string, status?: string): Promise<Developer> {
    const response = await http.get(`/developers/${developer_id}?status=${status}`);
    return decodeDeveloper(response.data);
}

export async function getDeveloperProfile(status?: DeveloperProfileStatus): Promise<Developer> {
    const profileUrl = status ? `/developers/my-profile?status=${status}` : "/developers/my-profile";
    const { data } = await httpDevProfile.get<Developer>(profileUrl);
    return decodeDeveloper(data);
}

function decodeDeveloper(developer: Developer) {
    developer.developer_name = decode(developer.developer_name);
    developer.developer_logo_s3_file_id = decode(developer.developer_logo_s3_file_id);
    developer.developer_about = decode(developer.developer_about);
    developer.developer_url = decode(developer.developer_url);
    developer.reason = decode(developer.reason);
    return developer;
}

export async function createDeveloper(developer: Developer): Promise<boolean> {
    let success = true;
    let message = "";

    await httpDevProfile.post("/developers", developer).catch((error) => {
        success = false;
        const result = error.response;

        if (result.status === HttpStatusCode.Conflict) {
            message = result.data.message;
        } else {
            message = errorMessages.post as string;
        }

        Vue.$toast?.error(message);
    });

    return success;
}

export async function updateDeveloper(developer_id: string, developer: Developer): Promise<boolean> {
    let success = true;
    let message = "";

    await httpDevProfile.put(`/developers/${developer_id}`, developer).catch((error) => {
        success = false;
        const result = error.response;

        if (result.status === HttpStatusCode.NotFound) {
            message = result.data.message;
        } else {
            message = errorMessages.put as string;
        }

        Vue.$toast?.error(message);
    });

    return success;
}
