
import { defineComponent } from "vue";
import NamedSection from "../publisher/NamedSection.vue";
import DeveloperProfileSection from "./DeveloperProfileSection.vue";

export default defineComponent({
    components: {
        NamedSection,
        DeveloperProfileSection,
    },
    props: {
        websiteLink: {
            type: String,
            required: false,
        },
        editing: {
            type: Boolean,
            required: true,
        },
        websiteLinkPrevious: {
            type: String,
            required: false,
        },
    },
    computed: {
        websiteLinkValue: {
            get() {
                return this.$props.websiteLink;
            },
            set(value: string) {
                this.$emit("update:websiteLink", value);
            },
        },
    },
    data() {
        return {
            validationRules: [
                (text: string): string | boolean => !!text || "Required",
                (text: string): string | boolean => text.length <= 100 || "Maximum 100 characters",
            ],
        };
    },
});
