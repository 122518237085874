
import Vue from "vue";
import Component from "vue-class-component";
import ApplicationProgressBar from "@/components/publisher/ApplicationProgressBar.vue";
import {
    createApplication,
    getApplication,
    updateApplication,
    updateApplicationStatus,
} from "@/api/ApplicationService";
import { updateApplicationFiles } from "@/api/ApplicationFilesService";
import { Application } from "@/models/Application";
import { Watch } from "vue-property-decorator";
import { Dictionary } from "vue-router/types/router";
import { ApplicationRoute, PublishRoute } from "@/router/route-names";
import ConfirmationModal from "@/components/shared/ConfirmationModal.vue";
import { ApiErrorResponse } from "@/utilities/axios-helpers";

@Component({
    metaInfo: {
        title: "Application Publishing",
    },
    components: {
        ApplicationProgressBar,
        ConfirmationModal,
    },
    computed: {
        requiredFieldsFilledIn(): boolean {
            return this.$store.state.currentApplicationRequiredFieldsFilled;
        },
        userHasDeveloperRole(): boolean {
            return this.$store.state.roles.includes("developer");
        },
        saveAndContinueButtonDisabled(): boolean {
            return (
                this.$store.state.currentApplication.status === "archived" ||
                this.$store.state.currentApplication.status === "recalled" ||
                this.$store.state.currentApplication.status === "submitted" ||
                (this.$route.name === "ApplicationSummary" && this.$store.state.currentApplication.status === "live")
            );
        },
    },
})
export default class Publish extends Vue {
    applicationId?: string;
    versionId?: string;
    versionDetailsId?: string;
    editing?: string;

    currentApplication!: Application;

    step!: number;
    dialogBackButtonDisplay = false;

    @Watch("$route.meta", { immediate: true, deep: true })
    @Watch("$route.query", { immediate: true, deep: true })
    onRouteChanged() {
        const { application_id, version_id, version_details_id, editing } = this.$route.query as Dictionary<string>;
        this.step = this.$route.meta?.step ?? 1;

        this.applicationId = application_id;
        this.versionId = version_id;
        this.versionDetailsId = version_details_id;
        this.editing = editing;
    }

    async created() {
        this.step = this.$route.meta?.step ?? 1;

        const { application_id, version_id, version_details_id } = this.$route.query as Dictionary<string>;

        if (application_id && version_id && version_details_id && this.$route.meta?.requiresApplication) {
            this.currentApplication = await getApplication(application_id, version_id, version_details_id);
            this.$store.commit("setApplication", this.currentApplication);
        }
    }

    beforeMount() {
        const { application_id, version_id, version_details_id, editing } = this.$route.query as Dictionary<string>;

        this.applicationId = application_id;
        this.versionId = version_id;
        this.versionDetailsId = version_details_id;
        this.editing = editing;
    }

    handleBackButtonModalConfirmation(): void {
        this.step = this.step - 1;
        this.dialogBackButtonDisplay = false;

        if (this.editing === "true") {
            const query = {
                application_id: this.applicationId,
                version_id: this.versionId,
                version_details_id: this.versionDetailsId,
            };

            this.$router.push({
                name: PublishRoute.summary,
                query,
            });
        } else {
            if (this.step === 0) {
                this.$router.push({ name: ApplicationRoute.dashboard });

                return;
            }

            this.$router.push({
                name: this.$route.meta?.previousRoute,
                query: {
                    application_id: this.applicationId,
                    version_id: this.versionId,
                    version_details_id: this.versionDetailsId,
                },
            });
        }
    }

    async handleSaveAndContinue() {
        let applicationId = this.applicationId;
        let versionId = this.versionId;
        let versionDetailsId = this.versionDetailsId;
        const nextRoute: string | undefined = this.$route.meta?.nextRoute;

        const createOrUpdateApplication = async (): Promise<boolean> => {
            const response =
                this.applicationId && this.versionId && this.versionDetailsId
                    ? await this.updateApplication()
                    : await this.createNewApplication();

            if (response?.status === 409) {
                Vue.$toast.error((response.data as ApiErrorResponse).message);
                return false;
            }

            const application = response?.data;

            this.$store.commit("setApplication", application);

            applicationId = application?.application_id;
            versionId = application?.version_id;
            versionDetailsId = application?.version_details_id;
            return true;
        };

        switch (this.$route.name) {
            case PublishRoute.files:
            case PublishRoute.argoWorkflow:
                await this.saveApplicationFiles();
                break;

            case PublishRoute.summary:
                await this.updateApplicationStatusToSubmitted();
                break;

            case PublishRoute.details:
            case PublishRoute.requirements:
                if (!(await createOrUpdateApplication())) {
                    return;
                }
                break;
        }

        if (this.editing === "true") {
            const query = {
                application_id: applicationId,
                version_id: versionId,
                version_details_id: versionDetailsId,
            };

            this.$router.push({
                name: PublishRoute.summary,
                query,
            });
        } else {
            const query =
                nextRoute !== ApplicationRoute.dashboard
                    ? {
                          application_id: applicationId,
                          version_id: versionId,
                          version_details_id: versionDetailsId,
                      }
                    : undefined;

            this.$router.push({
                name: nextRoute,
                query,
            });
        }
    }

    async handleSaveAndExit() {
        switch (this.$route.name) {
            case PublishRoute.files:
            case PublishRoute.argoWorkflow:
                await this.saveApplicationFiles();
                break;

            case PublishRoute.details:
            case PublishRoute.requirements:
                if (this.applicationId && this.versionId && this.versionDetailsId) {
                    await this.updateApplication();
                } else {
                    await this.createNewApplication();
                }
                break;
        }

        this.$router.push({ name: ApplicationRoute.dashboard });
    }

    private async createNewApplication() {
        const collaborators = this.$store.state.currentApplication.collaborators?.filter(
            (c: string) => c.trim() !== ""
        );
        this.$store.state.currentApplication.collaborators = collaborators;

        return await createApplication(this.$store.state.currentApplication, this.applicationId);
    }

    private async updateApplication() {
        if (!this.applicationId || !this.versionId || !this.versionDetailsId) {
            return;
        }

        const collaborators = this.$store.state.currentApplication.collaborators?.filter(
            (c: string) => c.trim() !== ""
        );
        this.$store.state.currentApplication.collaborators = collaborators;

        return await updateApplication(
            this.applicationId,
            this.versionId,
            this.versionDetailsId,
            this.$store.state.currentApplication
        );
    }

    private async saveApplicationFiles() {
        if (!this.applicationId || !this.versionId || !this.versionDetailsId) {
            return;
        }

        await updateApplicationFiles(
            this.applicationId,
            this.versionId,
            this.versionDetailsId,
            this.$store.state.currentApplicationFiles
        );
    }

    private async updateApplicationStatusToSubmitted() {
        if (this.applicationId && this.versionId && this.versionDetailsId) {
            const { status } = await updateApplicationStatus(
                this.applicationId,
                this.versionId,
                this.versionDetailsId,
                "submitted"
            );

            if (status === "submitted") {
                Vue.$toast.success("Application successfully submitted");
            }
        }
    }
}
