
import { defineComponent } from "vue";
import NamedSection from "../NamedSection.vue";
import SystemRequirementsInput from "./SystemRequirementInput.vue";

const valueOrDefault = (value: string) => {
    if (parseFloat(value) === 0) {
        return undefined;
    }
    return value;
};

export default defineComponent({
    components: {
        NamedSection,
        SystemRequirementsInput,
    },
    computed: {
        minRamValue: {
            get() {
                return valueOrDefault(this.minRam.toString());
            },
            set(value: number) {
                this.$emit("update:minRam", value);
            },
        },
        minGpuCoresValue: {
            get() {
                return valueOrDefault(this.minGpuCores.toString());
            },
            set(value: number) {
                this.$emit("update:minGpuCores", value);
            },
        },
        minCpuCoresValue: {
            get() {
                return valueOrDefault(this.minCpuCores.toString());
            },
            set(value: number) {
                this.$emit("update:minCpuCores", value);
            },
        },
        minDiskSpaceValue: {
            get() {
                return valueOrDefault(this.minDiskSpace.toString());
            },
            set(value: number) {
                this.$emit("update:minDiskSpace", value);
            },
        },
        wholeNumberValidationRules() {
            return [
                ...this.validationRules,
                (value: string): string | boolean =>
                    Number.isInteger(parseFloat(value)) || "Value should be a whole number",
            ];
        },
    },
    data() {
        return {
            validationRules: [
                (value: string): string | boolean => !!value || "Required",
                (value: string): string | boolean => parseFloat(value) > 0 || "Value should be greater than zero",
            ],
            wholeNumberValidationRulesNotRequired: [
                (value: string): string | boolean => {
                    if (value) {
                        return Number.isInteger(parseFloat(value)) || "Value should be a whole number";
                    }
                    return true;
                },
            ],
        };
    },
    props: {
        minRam: {
            type: Number,
            default: 0,
        },
        minCpuCores: {
            type: Number,
            default: 0,
        },
        minGpuCores: {
            type: Number,
            default: 0,
        },
        minDiskSpace: {
            type: Number,
            default: 0,
        },
    },
});
