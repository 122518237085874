
import Vue from "vue";
import Component from "vue-class-component";
import NamedSection from "../NamedSection.vue";
import { getAllInputTypes } from "@/api/InputOutputTypeService";
import { InputType } from "@/models/Application";
import { Prop } from "vue-property-decorator";
interface InputTypesOption {
    text: string;
    value: InputType;
}
function mapToInputTypeOptions(inputTypes: InputType[]): InputTypesOption[] | undefined {
    const formattedTypes = inputTypes.map((i) => ({ text: i.name, value: i }));
    if (formattedTypes.length === 0) {
        return undefined;
    }
    return formattedTypes;
}
@Component({
    components: {
        NamedSection,
    },
    computed: {
        inputTypesValue: {
            get() {
                return mapToInputTypeOptions(this.$props.inputTypes);
            },
            set(inputTypes: InputType[]) {
                const selectedIds = inputTypes.map((i) => i.id);
                const selected = (this.$data.inputTypesOptions as InputTypesOption[])
                    .filter((i) => selectedIds.includes(i.value.id))
                    .map((i) => i.value);
                this.$emit("update:inputTypes", selected);
            },
        },
    },
})
export default class InputTypes extends Vue {
    inputTypesOptions: InputTypesOption[] | undefined = [];
    validationRules = [(value: InputType[]): string | boolean => (!!value && value.length > 0) || "Required"];
    menuProps = { contentClass: "input-type-dropdown" };
    loading = false;
    @Prop({ default: () => [] }) inputTypes!: InputType[];

    async mounted() {
        this.loading = true;
        const options = await getAllInputTypes();
        this.inputTypesOptions = mapToInputTypeOptions(options);
        this.loading = false;
    }
}
