
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { Dictionary } from "vue-router/types/router";
import { ContainerCommand } from "@/models/Container";
import RequiredFieldsMessage from "@/components/publisher/RequiredFieldsMessage.vue";
import ApplicationExecutables from "@/components/publisher/container-upload/ApplicationExecutables.vue";
import ContainerUploadCommands from "@/components/publisher/container-upload/ContainerUploadCommands.vue";
import { getApplication } from "@/api/ApplicationService";
import { Application, ContainerImage } from "@/models/Application";
import { checkContainersUploaded, generateCommands } from "@/api/ApplicationContainersService";
import { redirectToAppSummary } from "./redirect";

@Component({
    metaInfo: {
        title: "Application Container Upload",
    },
    components: {
        RequiredFieldsMessage,
        ApplicationExecutables,
        ContainerUploadCommands,
    },
})
export default class ContainerUpload extends Vue {
    applicationId = "";
    versionId = "";
    versionDetailsId = "";
    requiredFieldsFilled = false;
    containerImages?: ContainerImage[] = [];
    containerCommands = {} as ContainerCommand;
    containersUploaded = false;
    application = {} as Application;
    showCommands = false;
    containerNamesChanged = false;
    loading = false;

    async created() {
        const { application_id, version_id, version_details_id } = this.$route.query as Dictionary<string>;

        this.applicationId = application_id;
        this.versionId = version_id;
        this.versionDetailsId = version_details_id;

        this.loading = true;
        this.application = await getApplication(this.applicationId, this.versionId, this.versionDetailsId);
        this.$store.commit("setApplication", this.application);

        this.containerImages = this.application.container_repositories;

        if (this.containerImages?.length && !this.application.version_locked) {
            const imageNames = this.containerImages?.map((image: ContainerImage) => image.name);
            this.containerCommands = await generateCommands(this.versionId, imageNames);

            const containersUploadedCheck = await checkContainersUploaded(this.versionId, imageNames);
            this.containersUploaded = containersUploadedCheck[0];
            this.requiredFieldsFilled = this.containersUploaded;
        } else if (this.application.version_locked) {
            const message = "Application is currently locked, you cannot edit the application containers.";
            redirectToAppSummary(message, application_id, version_id, version_details_id);
        }
        this.loading = false;
    }

    @Watch("requiredFieldsFilled")
    onRequiredFieldFilledIn(): void {
        this.$store.commit("setCurrentApplicationRequiredFieldsFilled", this.requiredFieldsFilled);
    }

    commandsGenerated(commands: ContainerCommand) {
        this.containerCommands = commands;
        this.requiredFieldsFilled = false;
    }

    showCommandsChange(show: boolean) {
        this.showCommands = show;
    }

    containerNamesChange(errorsExist: boolean) {
        this.containerNamesChanged = errorsExist;
        this.requiredFieldsFilled = !errorsExist;
    }

    containersUploadedChange(uploaded: boolean) {
        this.containersUploaded = uploaded;
        this.requiredFieldsFilled = uploaded;
    }
}
