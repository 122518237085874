
import Vue from "vue";
import Component from "vue-class-component";
import NamedSection from "../NamedSection.vue";
import { getAllOutputTypes } from "@/api/InputOutputTypeService";
import { OutputType } from "@/models/Application";
import { Prop } from "vue-property-decorator";
interface OutputTypesOption {
    text: string;
    value: OutputType;
}
function mapToOutputTypeOptions(outputTypes: OutputType[]): OutputTypesOption[] | undefined {
    const formattedTypes = outputTypes.map((i) => ({ text: i.name, value: i }));
    if (formattedTypes.length === 0) {
        return undefined;
    }
    return formattedTypes;
}
@Component({
    components: {
        NamedSection,
    },
    computed: {
        outputTypesValue: {
            get() {
                return mapToOutputTypeOptions(this.$props.outputTypes);
            },
            set(outputTypes: OutputType[]) {
                const selectedIds = outputTypes.map((i) => i.id);
                const selected = (this.$data.outputTypesOptions as OutputTypesOption[])
                    .filter((i) => selectedIds.includes(i.value.id))
                    .map((i) => i.value);
                this.$emit("update:outputTypes", selected);
            },
        },
    },
})
export default class OutputTypes extends Vue {
    outputTypesOptions: OutputTypesOption[] | undefined = [];
    validationRules = [(value: OutputType[]): string | boolean => (!!value && value.length > 0) || "Required"];
    menuProps = { contentClass: "output-type-dropdown" };
    loading = false;
    @Prop({ default: () => [] }) outputTypes!: OutputType[];

    async mounted() {
        this.loading = true;
        const options = await getAllOutputTypes();
        this.outputTypesOptions = mapToOutputTypeOptions(options);
        this.loading = false;
    }
}
